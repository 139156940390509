import React, { useEffect } from "react"
import Layout from "../components/layout"
import Seoinfo from "../components/seoinfo/seoinfo"
import GetInTouchSection from "../components/getInTouchSection/getInTouchSection"
import Hero from "../components/hero"

import AOS from "aos"

import WhoWeAre from "../images/LifeTeam.png"
import WhoWeAreMobile from "../images/LifeTeam-mobile.png"

import AboutUsDesktop from "../images/AboutUs1366.png"
import AboutUsDesktop1920 from "../images/AboutUs1920.png"
import AboutUsDesktop2800 from "../images/AboutUs2800.png"
import AboutUsMobile from "../images/AboutUsMobile.jpg"

import {
  WhoWeAreSection,
  Title,
  UnderLineTitle,
  Text,
  ImageDiv,
  ImageDivMobile,
} from "../styles/pages/aboutUs.jsx"

const AboutUS = () => {
  useEffect(
    () => {
      AOS.init({
        duration: 2000,
        once: true,
      })
    },
    [],
    AOS
  )

  const image = {
    desktop: AboutUsDesktop,
    mobile: AboutUsMobile,
    desktop1920: AboutUsDesktop1920,
    desktop2800: AboutUsDesktop2800,
  }
  return (
    <Layout>
      <Seoinfo
        siteName={"Life Home Therapy"}
        titlePage={"About Us"}
        description={
          "We are a team of dedicated professionals focused on treating all patients compassionately and with positive outcomes."
        }
        keywords={
          "therapy services, in-home services, in-home assistance, physical therapy, occupational therapy, speech-language pathology, home therapy, home therapy services"
        }
        type={"website"}
        url={"hometherapy.life/about-us"}
        imageFacebook={"/seo/about-us.png"}
      />
      <Hero
        image={image}
        title="THE LIFE HOME THERAPY TEAM"
        text="We work together for you."
        alt="Nurse assisting an elderly woman"
      />
      {/* <AboutusContent> */}
      <div data-aos="fade" className="container">
        <WhoWeAreSection>
          <Title>WHO WE ARE</Title>
          <UnderLineTitle />
          <Text>
            We are a team of dedicated professionals focused on treating all
            patients compassionately and with positive outcomes.
          </Text>
          <ImageDiv
            src={WhoWeAre}
            alt="Group of medical professionals sitting in a row smiling at the camera."
          />
          <ImageDivMobile
            src={WhoWeAreMobile}
            alt="Group of medical professionals sitting in a row smiling at the camera."
          />
          <Text moreMarginTop>
            Life Home Therapy focuses on designing an individual treatment
            plan based on your needs, intending to achieve the highest extent
            of recovery possible. Our mission is to rehabilitate lives by
            providing excellent care with compassion and respect.
          </Text>
        </WhoWeAreSection>
      </div>
      <GetInTouchSection maxWidth="1018px" />
      {/* </AboutusContent> */}
    </Layout>
  )
}

export default AboutUS
