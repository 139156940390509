import React, {useEffect} from "react";

import AOS from 'aos';

// Import SCSS file
import './styles.jsx';

import { HeroContainer, Title, Text, Wrapper, TextDiv} from './styles';

const Hero = ({alt, image, title, text, isFromServices}) => {

  useEffect(() => {
    AOS.init({
     
      duration : 2000,
      once: true
    });
  }, [], AOS);

  return(
    <HeroContainer isFromServices={isFromServices} image={image} alt={alt}>
      <Wrapper data-aos="fade">
        <TextDiv>
          <Title>
            {title}
            </Title>
          <Text>
            {text}
          </Text>
        </TextDiv>
      </Wrapper>
    </HeroContainer>
  )
}

export default Hero;
